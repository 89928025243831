<template>
    <div class="Conversioncode">
        <ul class="tab-navs spmg-bottom-db" style="margin-bottom:0px">
            <li class="tn-item current">
                卡密绑定
            </li>
        </ul>
        <div class="Conver_form" v-show="this.$store.state.vip == 'false' || this.$store.state.vip == false">
            <el-input v-model="password" placeholder="请输入卡密" @focus="focus"></el-input>
            <div class="keepOnline_wrap">
                <p class="form_errorMsg login_erroMsg">
                    {{msg}}
                </p>
            </div>
            <div class="div_button">
                <el-button type="primary" @click="exchange()">绑定</el-button>
            </div>
        </div>
        <div class="Conver_form" style="width:400px" v-show="this.$store.state.vip == 'true' || this.$store.state.vip == true">
            <el-form ref="ruleForm" :model="ruleForm" label-width="100px">
                <el-form-item label="电子卡名称">
                    <el-input v-model="ruleForm.vipName" disabled></el-input>
                </el-form-item>
                <el-form-item label="到期时间">
                    <el-input v-model="ruleForm.vipDate" disabled></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            password:'',
            msg:'',
            ruleForm:{
                vipName:'',
                vipDate:''
            }
        }
    },
    methods: {
        getapp(){
            if(this.$store.state.vip=='true' || this.$store.state.vip==true){
                this.ruleForm.vipName = this.$store.state.vipName
                this.ruleForm.vipDate = this.$store.state.vipDate
            }
        },
        focus(){
            this.msg = ''
        },
        exchange(){
            if(!this.password){
                this.msg='请输入卡密'
            }else{
                this.axios.myecardquery({
                    token:this.$store.state.token,
                    password:this.password
                }).then(res=>{
                    if(res.data.code==0){
                        this.$confirm('<div style="width:60%;margin:20px auto;"><p><span style="display:inline-block;width:80px; text-align: justify;text-justify: distribute;text-align-last: justify;"> 电子卡名称</span>：'+ res.data.data.name+'</p><p><span style="display:inline-block;width:80px; text-align: justify;text-justify: distribute;text-align-last: justify;"> 到期时间</span>：'+ res.data.data.endDate+'</p></div>', '信息', {
                            confirmButtonText: '确认绑定',
                            cancelButtonText: '取消',
                            dangerouslyUseHTMLString: true,
                            closeOnClickModal:false,
                            customClass:'aaa'
                        }).then(() => {
                            this.axios.myecardbind({
                                token:this.$store.state.token,
                                password:this.password
                            }).then(res=>{
                                if(res.data.code==0){
                                    this.$message({
                                        type: 'success',
                                        message: '绑定成功!'
                                    });
                                    this.msg = ''
                                    this.$store.dispatch("vip",res.data.data.vip)
                                    this.$store.dispatch("vipName",res.data.data.vipName)
                                    this.$store.dispatch("vipDate",res.data.data.vipDate)
                                    location.reload();
                                }else{
                                    this.msg = res.data.message
                                }
                            }).catch(err=>{
                                console.log(err)
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消绑定'
                            });          
                        });
                    }else{
                        this.msg = res.data.message
                    }
                }).catch(err=>{
                    console.log(err)
                })
            }
        }
    },
    mounted() {
         this.getapp()   
    }
}
</script>
<style scoped>
@import "./../../styles/Page/Conversioncode.css";
</style>
<style>
.aaa .el-message-box__btns{
    text-align: center;
}
</style>